import { DraftWizardExtended } from "@/components/DraftWizardExtended.js";
import { GAdsConversionTracker } from "@/components/GAdsConversionTracker.js";
import { PricingTable } from "@/components/PricingTable.js";
import { useUser } from "@/components/hooks/useUser.js";

import { ListDocs, ListFeaturedDocs, ListPublishedDocs } from "./ListDocs.js";
import { ListDrafts } from "./ListDrafts.js";

export const Home = () => {
  const { user } = useUser();

  return (
    <div className="space-y-6 mt-5">
      <GAdsConversionTracker />

      <DraftWizardExtended />

      {((user && user.credits.proposals > 0) || user?.instantDraftDisabled) && (
        <PricingTable closed={true} />
      )}

      <ListPublishedDocs
        desc="Grant, RFP, LOI, Report templates curated by the GrantOrb team"
        limit={2}
        publicDocs={true}
        templateIds={["grant", "loi", "rfp"]}
        title="Opportunities"
      />

      {!user?.hasDocs ? (
        <ListFeaturedDocs />
      ) : (
        <>
          <ListDrafts limit={5} />
          <ListPublishedDocs
            bookmarked={true}
            publicDocs={false}
            title="Your bookmarks"
          />
          <ListDocs limit={5} />
        </>
      )}
    </div>
  );
};
